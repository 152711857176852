export const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "Common" */ './layouts/front/index.vue'),
        children: [
            // 테스트
            {
                path: '/tests',
                meta: { sitemap: { ignoreRoute: true } },
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: '/',
                        component: () => import(/* webpackChunkName: "Common" */ '@/tests/index.vue')
                    },
                ]
            },
            
            {
                path: '/',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/home.vue')
            },
            {
                path: 'search',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/search.vue')
            },
            {
                path: 'genre',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/board/genre.vue')
            },
            {
                path: 'mood',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/board/mood.vue')
            },
            {
                path: 'community',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/board/community.vue')
            },
            {
                path: 'my',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/my.vue'),
                children: [
                    {
                        path: 'message',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/my/message.vue')
                    },
                    {
                        path: 'article',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/my/article.vue')
                    },
                    {
                        path: 'comment',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/my/comment.vue')
                    },
                    {
                        path: 'save',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/my/save.vue')
                    },
                    {
                        path: 'update',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/my/update.vue')
                    },
                ]
            },
            {
                path: 'auth',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/Login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/Logout.vue')
                    },
                    {
                        path: 'join',
                        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'agree',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/join/agree.vue')
                            },
                            {
                                path: 'form',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/join/form.vue')
                            }
                        ]
                    },
                    {
                        path: 'find',
                        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'id',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/find/id.vue')
                            },
                            {
                                path: 'password',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/find/password.vue')
                            }
                        ]
                    },
                    {
                        path: 'email_certification',
                        component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/EmailCertification.vue')
                    }
                ]
            },

            // Policy
            {
                path: 'policy',
                component: () => import(/* webpackChunkName: "Policy" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'privacy',
                        component: () => import(/* webpackChunkName: "Policy" */ '@/views/policy/Privacy.vue'),
                    },
                ]
            }
        ]
    },

    // 관리자 페이지
    {
        path: '/admin',
        meta: { sitemap: { ignoreRoute: true } },
        component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/admin/index.vue'),
        children: [
            // Auth
            {
                path: 'auth',
                component: () => import(/* webpackChunkName: "Admin" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/auth/login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Admin" */ '@/views/admin/auth/logout.vue')
                    },
                ]
            },

            // 홈 관리
            {
                path: 'home',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/home.vue'),
            },

            // 회원 관리
            {
                path: 'banner',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/banner.vue'),
            },

            // GENRE 관리
            {
                path: 'genre',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/genre.vue'),
            },

            // MOOD 관리
            {
                path: 'mood',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/mood.vue'),
            },

            // COMMUNITY 관리
            {
                path: 'community',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/community.vue'),
            },

            // 카테고리 관리
            {
                path: 'category',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/category.vue'),
            },

            // 회원 관리
            {
                path: 'user',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/user.vue'),
            },

            // 관리자 정보수정
            {
                path: 'update',
                component: () => import(/* webpackChunkName: "Common" */ '@/views/admin/update.vue'),
            }
        ]
    }
]