import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.orange,
                admin: "#313942",
            },
        },

        breakpoint: {
            mobileBreakpoint: 600
        },
    },  
})
