<template>
    <v-app style="padding:0 !important; margin:0 !important;">
        <router-view :key="$route.fullPath"></router-view>
    </v-app>
</template>
<script>
export default {
    name: 'App'
}
</script>
